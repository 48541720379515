<template>
  <div v-if="licenseLoading === true">
    <CRow>
      <CCol cols="12" lg="12">
        <loadingSpinner mode="auto" :content="$t('admin.Loading_company_details')"/>
      </CCol>
    </CRow>      
  </div>
  <div v-else>  
    <CRow class="m-0">
      <CCol cols="12" lg="12">
        <label><b>{{$t('admin.General_license')}}</b></label>
        <div>
          <CRow>
            <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="pt-0 pb-0">
              <div class="d-flex align-items-center mb-2">
                <span class="mr-1">{{ $t('core.Current_license_code') }}:</span>
                <span v-if="licenseData.license_code" class="d-flex align-items-center">
                  <span>{{licenseData.license_code}} ({{licenseData.license_name}})</span>
                  <CBadge v-if="licenseData.activated === 1" color="success" class="ml-1">{{ $t('common.Active') }}</CBadge>
                  <CBadge v-else class="inactive ml-1">{{ $t('common.Inactive') }}</CBadge>
                </span>
              </div>
              <div class="d-flex align-items-center">
                <span class="mr-1">{{ $t('core.Max_user_amount') }}:</span>
                <span v-if="licenseData.max_users" class="d-flex align-items-center">
                  <span>{{licenseData.max_users}} {{$t('common.users')}}</span>
                </span>
              </div>                                           
            </CCol>
          </CRow>
          <CRow>
            <CCol cols="12" lg="12" class="pb-0">
              <CButton color="primary" @click="licenseModal = true; resetLicenseData();"><i class="fas fa-pen mr-1"/>{{ $t('core.Update_license_code') }}</CButton>
            </CCol>
          </CRow>          
        </div>
      </CCol>
    </CRow>    
    <CRow v-if="licenseData.license_code" class="m-0">
      <CCol cols="12" lg="12" class="pt-0 pb-0">
        <label><b>{{$t('admin.Product_licenses')}}</b></label>
        <div>
          <CRow>
            <CCol cols="4" lg="4" md="12" sm="12" class="pt-0">
              <label>Loyalty</label>
              <CButtonGroup class="w-100">
                <CButton color="outline-secondary" v-for="(license, key) in productLicenses" :key="key" :pressed="license.product_license_tag === licenseData.loyalty_license_tag ? true : false" @click="openConfirmationModal('loyalty', license)">
                  <span>{{license.product_license_title}}</span>
                </CButton>
              </CButtonGroup>
            </CCol>
            <CCol cols="4" lg="4" md="12" sm="12" class="pt-0">
              <label>Connect</label>
              <CButtonGroup class="w-100">
                <CButton color="outline-secondary" v-for="(license, key) in productLicenses" :key="key" :pressed="license.product_license_tag === licenseData.connect_license_tag ? true : false" @click="openConfirmationModal('connect', license)">
                  <span>{{license.product_license_title}}</span>
                </CButton>
              </CButtonGroup>
            </CCol>
            <CCol cols="4" lg="4" md="12" sm="12" class="pt-0">
              <label>Measure</label>
              <CButtonGroup class="w-100">
                <CButton color="outline-secondary" v-for="(license, key) in productLicenses" :key="key" :pressed="license.product_license_tag === licenseData.measure_license_tag ? true : false" @click="openConfirmationModal('measure', license)">
                  <span>{{license.product_license_title}}</span>
                </CButton>
              </CButtonGroup>
            </CCol>
          </CRow>
        </div>
      </CCol>
    </CRow>             
  
    <b-modal class="target pl-0 pr-0" :can-cancel="[]" :active.sync="licenseModal" :width="960" scroll="keep">
      <CCard class="mb-0">
        <CCardHeader class="pb-0">            
          {{ $t('core.Update_license_code') }}
        </CCardHeader>
        <CCardBody>          
          <CRow class="p-0">
            <CCol cols="12" lg="12" class="pt-0 pb-0">            
              <CInput class="mb-0" type="text" :label="$t('core.Enter_license_code')" min="0" max="10" step="0.1" v-model="licenseModalData.license_code"/>              
              <p v-if="licenseErrorMessage !== null" class="error mt-2">{{licenseErrorMessage}}</p>
            </CCol>
          </CRow>
        </CCardBody>
        <CCardFooter>
          <div>
            <CButton color="primary" @click="updateLicense(licenseModalData);" :disabled="licenseModalData.license_code === null || licenseModalData.license_code === ''">
              <i class="fas fa-save mr-1"/>{{ $t('Update') }}
            </CButton>
            <CButton color="secondary" @click="licenseModal = false; resetLicenseData()">
              <i class="fas fa-times mr-1"/>{{ $t('Cancel') }}
            </CButton>
          </div>                  
        </CCardFooter>
      </CCard>
    </b-modal>

    <b-modal class="target pl-0 pr-0" :can-cancel="[]" :active.sync="confirmationModal" :width="960" scroll="keep">
      <CCard v-if="pendingLicenseChange" class="mb-0">
        <CCardHeader class="pb-0">
          {{$t('admin.Confirm_product_license_change', { product_license_title: pendingLicenseChange.product_license_title })}}
        </CCardHeader>
        <CCardBody>          
          <p>{{$t('admin.Are_you_sure_to_change_product_license', { product_name: formatProductName(pendingLicenseChange.product_tag), product_license_title: pendingLicenseChange.product_license_title })}}</p>
        </CCardBody>
        <CCardFooter>
          <div>
            <CButton color="primary" @click="updateProductLicense();">
              <i class="fas fa-check mr-1"/>{{ $t('Confirm') }}
            </CButton>
            <CButton color="secondary" @click="resetPendingLicenseChange(); confirmationModal = false;">
              <i class="fas fa-times mr-1"/>{{ $t('Cancel') }}
            </CButton>
          </div>                  
        </CCardFooter>
      </CCard>
    </b-modal>  

  </div>  
</template>

<script>
import axios from 'axios'
import loadingSpinner from '@/components/common/loadingSpinner.vue';

export default {
  name: 'CompanyLicensing',
  components: {
    loadingSpinner
  },
  data() {
    return {
      productLicenses: [],
      licenseData: {},
      licenseModal: false,
      licenseModalData: { license_code: null },
      licenseErrorMessage: null,
      licenseLoading: false,
      licenseLoaded: false,
      confirmationModal: false,
      pendingLicenseChange: {
        product_tag: null,
        product_license_tag: null
      }      
    }
  },
  methods: {
    getProductLicenses() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/licenses/products')
      .then(res => {
        this.productLicenses = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getLicenseData() {
      // Start the loader
      if(this.licenseLoaded = false) this.licenseLoading = true;
      // Get the license data
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/license')
      .then(res => {
        this.licenseData = res.data.data;
        // Stop the loader
        this.licenseLoading = false;
        // Update the licenseLoaded data
        this.licenseLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    updateLicense(licenseModalData) {
      let params = {};
      params = licenseModalData;
      // Reset the license error message
      this.licenseErrorMessage = null;
      // Update the license for the company
      axios.put(process.env.VUE_APP_API_URL + '/v1/core/license', params)
      .then(res => {
        // Show a success notice
        this.$buefy.toast.open({ message: this.$t('core.License_code_updated'), type: 'is-success', duration: 2000 });
        // Get the updated license data
        this.getLicenseData();
        // Close the modal
        this.licenseModal = false;
        // Reset the license error message
        this.licenseErrorMessage = null;
      })
      .catch(err => {
        // Show correct error message according to error
        if(err.response.data.error === 'Invalid license code') {
          this.licenseErrorMessage = this.$t('core.Invalid_license_code');
        } else if(err.response.data.error === 'Same license') {
          this.licenseErrorMessage = this.$t('core.Same_license');
        }
      });
    },    
    resetLicenseData() {
      this.licenseErrorMessage = null;
      this.licenseModalData = {
        license_code: null
      };
    },
    openConfirmationModal(productTag, productLicenseData) {
      // Store the pending change
      this.pendingLicenseChange = {
        product_tag: productTag,
        product_license_title: productLicenseData['product_license_title'],
        product_license_tag: productLicenseData['product_license_tag']
      };
      // Open the confirmation modal
      this.confirmationModal = true;
    },    
    updateProductLicense() {
      let params = {};
      params.product_tag = this.pendingLicenseChange.product_tag;
      params.product_license_title = this.pendingLicenseChange.product_license_title;
      params.product_license_tag = this.pendingLicenseChange.product_license_tag;
      
      axios.put(process.env.VUE_APP_API_URL + '/v1/core/license/product', params)      
      .then(() => {
        // Show success message
        this.$buefy.toast.open({ message: this.$t('admin.Product_license_updated', { product_name: this.formatProductName(params.product_tag), product_license_title: params.product_license_title }), type: 'is-success', duration: 2000 });
        // Refresh license data
        this.getLicenseData();
        // Reset the pending license change
        this.resetPendingLicenseChange();
        // Close the modal
        this.confirmationModal = false;               
      })
      .catch(err => {
        console.error(err);
        // Reset the pending license change
        this.resetPendingLicenseChange();
        // Close the modal
        this.confirmationModal = false;        
      });
    },
    resetPendingLicenseChange() {
      this.pendingLicenseChange = {
        product_tag: null,
        product_license_tag: null
      };      
    },
    formatProductName(name) {      
      if(name) return name.charAt(0).toUpperCase() + name.slice(1);
    }    
  },
  mounted: function() {
    this.getProductLicenses();
    this.getLicenseData();
    this.resetLicenseData();
    this.resetPendingLicenseChange();
  }
}
</script>
